/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/mystyles.scss"

const addScript = () => {
  const script1 = document.createElement("script")
  script1.src = "https://viewer.sayduck.com"
  script1.type = "Module"
  script1.async = true
  document.body.appendChild(script1)
  const script2 = document.createElement("script")
  script2.src = "https://identity.netlify.com/v1/netlify-identity-widget.js"
  document.body.appendChild(script2)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript()
  }
}

// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => {
  var setCookie = (cName, cValue, expDays) => {
    let date = new Date()
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000)
    const expires = "expires=" + date.toUTCString()
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/"
  }
  //set cookie to show service worker alert
  console.log("service worker update ready")
  setCookie("swVersion", "", -10)
}
